<template>
  <div class="login-model">
    <div class="register" v-if="loginStatus==1||loginStatus==2">
      <el-dialog
          :visible.sync="showModel"
          width="528px"
          center @close="closeLoginModel">
        <div class="content" v-if="loginStatus==1">
          <div class="input-content">
            <div class="input-tilte">
              <div class="title">账号注册</div>
              <div class="line"></div>
            </div>
            <div class="input-item">
              <el-input v-model="phone" placeholder="请输入手机号"></el-input>
            </div>
            <div class="input-item">
              <el-input v-model="code" max-length="6" placeholder="请输入验证码"></el-input>
              <span class="code-msg" @click="getCode(1)">{{text}}</span>
            </div>
            <div class="input-item">
              <el-input v-model="pwd" type="password" placeholder="请输入密码" @keyup.enter.native="register"></el-input>
            </div>
            <div class="tip flex-items">
              <img @click="chooseTip" v-if="isCheck" src="../assets/images/choose-a.png" alt="">
              <img @click="chooseTip" v-else src="../assets/images/choose.png" alt="">
              <div>
                我已阅读并同意 <span @click="goXy">《用户协议》</span>
              </div>
            </div>
            <div class="btn">
              <el-button @click="register">确认</el-button>
            </div>


          </div>
          <div class="else-btn" @click="chooseLoginType(3)">已有账号，去登录 <i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="content" v-if="loginStatus==2">
          <div class="input-content">
            <div class="input-tilte">
              <div class="title">忘记密码</div>
              <div class="line"></div>
            </div>
            <div class="input-item">
              <el-input v-model="phone" placeholder="请输入手机号"></el-input>
            </div>
            <div class="input-item">
              <el-input v-model="code" max-length="6" placeholder="请输入验证码"></el-input>
              <span class="code-msg" @click="getCode(2)">{{text}}</span>
            </div>
            <div class="input-item">
              <el-input v-model="nPwd" type="password" placeholder="请输入新密码" @keyup.enter.native="forgetPwd"></el-input>
            </div>
            <div class="btn">
              <el-button  @click="forgetPwd">确认</el-button>
            </div>


          </div>
          <div class="else-btn" @click="chooseLoginType(3)">登录 <i class="el-icon-arrow-right"></i></div>
        </div>
        <!--    <el-button @click="closeLoginModel">取 消</el-button>-->
        <!--    <el-button type="primary" @click="closeLoginModel">确 定</el-button>-->

      </el-dialog>
    </div>
    <div class="login" v-if="loginStatus==3">
      <el-dialog
          :visible.sync="showModel"
          center
          :show-close="false"
      >
        <div class="content flex-items" >
          <i class="close-icon el-icon-close" @click="closeLoginModel"></i>
          <div class="login-bg">
            <img src="../assets/images/login-bg.png" alt="">
          </div>
          <div class="input-content">
            <div class="input-tilte flex-items">
              <div class="title-item" @click="changeTab(1)">
                <div class="title">密码登录</div>
                <div class="line" :class="tabId==1?'active':''"></div>
              </div>
              <div class="title-item" @click="changeTab(2)">
                <div class="title">验证码登录</div>
                <div class="line" :class="tabId==2?'active':''"></div>
              </div>

            </div>
            <div class="input-item">
              <el-input v-model="phone" placeholder="请输入手机号"></el-input>
            </div>
            <div class="input-item" v-if="tabId==2">
              <el-input v-model="code" max-length="6" placeholder="请输入验证码" @keyup.enter.native="goLogin"></el-input>
              <span class="code-msg" @click="getCode(3)">{{text}}</span>
            </div>
            <div class="input-item" v-if="tabId==1">
              <el-input v-model="pwd" type="password" placeholder="请输入密码" @keyup.enter.native="goLogin"></el-input>
            </div>
            <div  class="else-btn flex-items flex-between">
              <div class="btn1" @click="chooseLoginType(1)">
                还没有账号？<span>去注册</span>
              </div>
              <div class="btn2" @click="chooseLoginType(2)">
                <span>忘记密码</span>
              </div>
            </div>
            <div  class="btn">
              <el-button @click="goLogin">登录</el-button>
            </div>
          </div>
        </div>
        <!--    <el-button @click="closeLoginModel">取 消</el-button>-->
        <!--    <el-button type="primary" @click="closeLoginModel">确 定</el-button>-->

      </el-dialog>
    </div>

  </div>
</template>

<script>
export default {
  name: "LoginModel",
  data(){
    return{
      isCheck:false,
      text:'获取验证码',
      phone:'',
      code:'',
      pwd:'',
      nPwd:'',
      timer:null,
      disabled:false,
      disabled1:false,
      disabled2:false,
      disabled3:false,
      tabId:1
    }
  },
  computed:{
    loginStatus(){
      return this.$store.state.loginType
    },
   showModel(){
     return this.$store.state.loginModel
   }

  },
  mounted(){

  },
  methods:{
    chooseTip(){
      this.isCheck=!this.isCheck
    },
    getCode(type){
      let phone=this.phone;
      if (this.disabled){
        return
      }
      if (!this.regPhone(phone)){
        return this.$message.error('请输入正确的手机号')
      }
      this.disabled = true;
      this.$get('/api/sms',{phone:this.phone, type:type ==1?'register':type ==2?'reset_password':'login'}).then(()=>{
        this.isSend=true;
        this.countdown();
      }).catch(()=>{
        this.disabled=false
      })

    },
    countdown() {
      // 验证码倒计时
      const TIME_COUNT = 60;
      if (!this.timer) {
        let count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (count > 0 && count <= TIME_COUNT) {
            count--;
            this.text = count + "秒后重试";
          } else {
            this.disabled1 = false;
            clearInterval(this.timer);
            this.timer = null;
            this.text = "发送验证码";
          }
        }, 1000);
      }
    },
    changeTab(id){
      this.tabId=id;
      clearInterval(this.timer);
      this.text = "发送验证码";
      this.pwd='';
    },
    goLogin(){
      let {phone,pwd,code,tabId}=this;
      let params={};
      if (this.disabled3){
        return
      }
      if (tabId==1){
        if (!this.regPhone(phone)){
          return this.$message.error('请输入正确的手机号')
        }
        if (!this.regLoginPwd(pwd)){
          return this.$message.error('请输入6-16位密码')
        }
        params={
          phone:phone,type:'password',password:pwd
        }
      }else{
        if (!this.regPhone(phone)){
          return this.$message.error('请输入正确的手机号')
        }
        if (!this.regCode(code)){
          return this.$message.error('请输入正确的验证码')
        }
        params={
          phone:phone,type:'sms',code:code
        }
      }
      this.disabled3=true
      this.$post('/api/authorizations',params).then(res=>{

        let token=res.data.access_token;
         this.$store.commit("setToken", token);
         this.closeLoginModel();
         this.clearData()
         this.getUserInfo()
         this.navTo('/')
      }).catch(err=>{
        this.disabled3=false
        console.log(err)
      })
    },
    register(){
      let phone=this.phone;
      let code=this.code;
      let pwd=this.pwd;

      if (this.disabled1){
        return
      }
      if (!this.regPhone(phone)){
        return this.$message.error('请输入正确的手机号')
      }
      if (!this.regCode(code)){
        return this.$message.error('请输入正确的验证码')
      }
      if (!this.regLoginPwd(pwd)){
        return this.$message.error('请输入6-16位密码')
      }
      if (!this.isCheck){
        return this.$message.error('请阅读并同意用户协议')
      }
      let params={
        phone:phone,
        code:code,
        password:pwd
      }
      this.disabled1=true
     this.$post('/api/user',params).then(res=>{
       this.$message.success(res.msg)
       let token=res.data.access_token;
       this.$store.commit("setToken", token);
       this.closeLoginModel();
       this.clearData()
       this.getUserInfo()
       this.navTo('/')

     }).catch(()=>{
       this.disabled1=false
     })
    },
    forgetPwd(){
      let phone=this.phone;
      let code=this.code;
      let pwd=this.nPwd;

      if (this.disabled2){
        return
      }
      if (!this.regPhone(phone)){
        return this.$message.error('请输入正确的手机号')
      }
      if (!this.regCode(code)){
        return this.$message.error('请输入正确的验证码')
      }
      if (!this.regLoginPwd(pwd)){
        return this.$message.error('请输入6-16位新密码')
      }

      let params={
        phone:phone,
        code:code,
        password:pwd
      }
      this.disabled2=true
      this.$post('/api/user/password',params).then(res=>{
        this.$message.success(res.msg)
       this.chooseLoginType(3);
        this.clearData()

      }).catch(()=>{
        this.disabled12=false
      })
    },
    getUserInfo(){
      this.$get('/api/user').then(res=>{
        this.setUserInfos(res.data)
      })
    },
    clearData(){
          this.isCheck=false;
          this.text='获取验证码';
          this.phone='';
          this.code='';
          this.pwd='';
          this.nPwd='';
          this.disabled=false;
          this.disabled1=false;
      this.disabled2=false;
      this.disabled3=false;
          this.tabId=1;
      clearInterval(this.timer);
    },
    checkLogin() {
      this.$get('/api/authorizations/check').then(() => {

      })
    },
    goXy(){
      this.navTo('/userAgreement')
      this.closeLoginModel()
    }
  }
}
</script>

<style  lang="scss">
  .login-model{
    .register{
      .el-dialog{
        width: 528px;
        //height: 618px;
        background: #FFFFFF;
        border-radius: 20px;
        .content{
          .input-content{
            padding: 30px 104px 40px 70px;
            .input-tilte{
              border-bottom: 1px solid #D8D8D8;
              display: flex;
              flex-direction: column;
              margin-bottom: 43px;
              .title{
                font-size: 24px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
              }
              .line{
                margin-top:10px;
                height: 4px ;
                width:89px;
                background-color: #F42323;
                border-radius:50px
              }
            }
            .input-item{
              margin-bottom: 25px;
              position: relative;
              .el-input__inner{
                height: 50px;
                line-height: 50px
              }
              .code-msg{
                position: absolute;
                top:15px;
                right:15px;
                z-index: 999;
                color:#f00;
                cursor: pointer;
              }
            }
            .input-item:last-child{
              margin-bottom: 0;
            }
            .tip{
              img{
                width: 19px;
                height: 19px;
                margin-right: 3px;
                cursor: pointer;
              }
              div{
                ont-size: 14px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 400;
                color: #999999;
                span{
                  color:#404B7C;
                  cursor: pointer;
                }
              }
            }
            .btn{
              margin-top: 29px;
              .el-button{
                width: 100%;
                line-height: 55px;
                background: #F42323;
                //box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
                border-radius: 4px;
                padding:0;
                font-size: 18px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

          }
          .else-btn{
            text-align: right;
            padding-right:22px;
            font-size: 18px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 500;
            color: #333333;
            cursor: pointer;
            i{
              color: #F42323
            }
          }
        }
      }
    }
    .login{
      .el-dialog{
        width: 775px;
        height: 492px;
        background: #FFFFFF;
        border-radius: 20px;
        .el-dialog__header{
          padding:0
        }
        .el-dialog--center .el-dialog__body,.el-dialog__body{
          padding:0
        }
        .content{
          position: relative;
          .close-icon{
            position: absolute;
            top: 20px;
            right: 20px;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
          }
          .login-bg{
            img{
              width:284px;
              height:492px
            }
          }
          .input-content{
             flex:1;
            margin-left: 60px;
            padding-right: 77px;
            .input-tilte{
              border-bottom: 1px solid #D8D8D8;
              margin-bottom: 43px;
              .title-item{
                display: flex;
                flex-direction: column;

                margin-right:20px;
                cursor: pointer;
              }
              .title{
                font-size: 18px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
              }
              .line{
                margin-top:10px;
                height: 4px ;
                width:89px;
                background-color: transparent;
                border-radius:50px
              }
              .line.active{
                background-color: #F42323;
              }
            }
            .input-item{
              margin-bottom: 25px;
              position: relative;
              .el-input__inner{
                height: 50px;
                line-height: 50px
              }
              .code-msg{
                position: absolute;
                top:15px;
                right:15px;
                z-index: 999;
                color:#f00;
                cursor: pointer;
              }
            }
            .input-item:last-child{
              margin-bottom: 0;
            }
            .tip{
              img{
                width: 19px;
                height: 19px;
                margin-right: 3px;
                cursor: pointer;
              }
              div{
                ont-size: 14px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 400;
                color: #999999;
                span{
                  color:#404B7C;
                  cursor: pointer;
                }
              }
            }
            .else-btn{
              cursor: pointer;
              span{
                color: #404B7C
              }
            }
            .btn{
              margin-top: 29px;
              .el-button{
                width: 100%;
                line-height: 55px;
                background: #F42323;
                //box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
                border-radius: 4px;
                padding:0;
                font-size: 18px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

          }

        }
      }
    }
  }
</style>
