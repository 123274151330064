<template>
	<div class="user-info">
		<div class="title-content">
			<div class="title">个人信息</div>
			<div class="line"></div>
		</div>
		<div class="edit-content">
			<div class="upload-item flex-items">
				<div class="avatar">
					<img :src="info.avatar" alt="">
				</div>
				<el-upload ref="upload" action="https://api.jxmzjzfw.com/api/upload" :limit="1" name="image"
					accept="image/jpg,image/jpeg,image/png" :show-file-list="false" :on-success="handleImageSuccess"
					:on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-error="handleError">
					<div class="up-text">
						<span class="tip">选择头像</span>
					</div>

				</el-upload>
			</div>
			<div class="edit-text flex-items">
				<div class="title">昵称</div>
				<el-input type="text" placeholder="请输入昵称" v-model="info.nickname">
				</el-input>
			</div>
			<div class="up-btn">
				<el-button class="btn" :disabled="disable" @click="sub">保存</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "userInfo",
		data() {
			return {
				info: {},
				path: '',
				dialogImageUrl: '',
				dialogVisible: false,
				text: '',
				imgs: [],
				disable: false
			}
		},
		mounted() {
			this.getUserInfo()
		},
		methods: {
			getUserInfo() {
				this.$get('/api/user').then(res => {
					this.info = res.data;
					this.setUserInfos(res.data)
				})
			},
			handleImageSuccess(response) {
				let data = response.data
				this.info.avatar = data.url
				this.path = data.path
			},
			handleError() {
				this.$message.error('上传失败')

			},
			handleRemove(file) {
				let imgPath = file.response.data.url;
				let list = this.imgs;
				for (let i = 0, len = list.length; i < len; i++) {
					if (list[i] == imgPath) {
						list.splice(i, 1);
						break
					}
				}
				this.imgs = list
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			sub() {
				let info = this.info;
				if (!info.nickname) {
					return this.$message.error('请填写昵称')
				}
				this.disable = true
				this.$put('/api/user', {
					nickname: info.nickname,
					avatar: this.path
				}).then((res) => {
					this.$message.success(res.msg)
					this.getUserInfo()
					this.disable = false
				}).catch(() => {
					this.disable = false
				})
			}
		}
	}
</script>

<style lang="scss">
	.user-info {
		.edit-content {
			padding: 52px 31px;

			.upload-item {
				.avatar {
					width: 126px;
					height: 126px;
					border: 1px dashed #ddd;
					border-radius: 10px;
					margin-right: 18px;

					img {
						width: 126px;
						height: 126px;
						border-radius: 10px;
						object-fit: cover;
					}
				}

				.up-text {
					background: #FFCCCC;
					border-radius: 32px;
					font-size: 14px;
					font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
					font-weight: 500;
					color: #F42323;
					padding: 5px 14px;
				}
			}

			.edit-text {
				margin-top: 25px;

				.title {
					font-size: 14px;
					font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
					font-weight: 500;
					color: #666666;
					margin-right: 18px;
					word-break: keep-all;
				}

				.el-input__inner {
					width: 220px;
					height: 55px;
					line-height: 55px;
					background: #F6F6F6;
					border-radius: 5px 5px 5px 5px;
					opacity: 1;
					border: 1px solid #E5E5E5;
				}

			}

			.up-btn {
				margin-top: 50px;

				.btn {
					width: 128px;
					height: 46px;
					background: #F42323;
					box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
					border-radius: 28px;
					font-size: 18px;
					font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
					font-weight: 500;
					color: #FFFFFF;
				}

			}
		}
	}
</style>