<template>
  <div class="common-content">
    <div class="title">{{ info.title }}</div>
    <div class="info" v-html="info.content"></div>
  </div>
</template>

<script>
export default {
  name: "userAgreement",
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$get('/api/article', {type: 4}).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>
