<template>
   <div class="kefu">
     <div class="title-content">
       <div class="title">平台客服</div>
       <div class="line"></div>
     </div>
     <div class="kefu-content flex-items flex-center">
       <div class="kefu-item item1">
         <div class="info" v-if="info[0]">
           <div>
             {{ info[0].name }}
           </div>
           <div>
             {{ info[0].telephone }}
           </div>

         </div>
       </div>
       <div class="kefu-item item2" v-if="info[1]">
         <div class="info">
           <div>{{ info[1].name }}</div>
           <div>{{ info[1].telephone }}</div>

         </div>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "ptkf",
  data(){
    return{
      info:[]
    }
  },
  mounted(){
    this.getInfo()
  },
  methods:{
    getInfo(){
      this.$get('/api/customer-service').then(res=>{
          this.info = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .kefu{
    .kefu-content{
      margin-top: 153px;
      .kefu-item{
        width:203px;
        height:169px;
        background-size: 100% 100%;
        position: relative;
        .info{
          position: absolute;
          left: 0;
          bottom: 7px;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          color: #FFFFFF;

        }
      }
      .item1{
        background-image: url('../../../assets/images/kefu1.png');
        margin-right:65px;
      }
      .item2{
        background-image: url('../../../assets/images/kefu2.png');
      }
    }
  }
</style>
