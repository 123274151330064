<template>
   <div class="cjwt">
     <div class="title">常见问题</div>
     <div class="item-content flex-items flex-between">
       <div class="item" v-for="(item,index) in msgList" :key="index">

            <div class="s-title flex-items">
              <img src="../../assets/images/wen.png" />
              <div>{{ item.title }}</div>
            </div>

         <div class="btn">
           <div @click="goDetails(item.id)">查看详情</div>
         </div>
       </div>
     </div>
     <div class="page-view">
       <el-pagination
           :current-page="page"
           :page-sizes="[10, 20, 50, 100]"
           :page-size="pageSize"
           background
           layout="total,sizes,prev, pager, next"
           :total="total"
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
       >
       </el-pagination>
     </div>
   </div>

</template>

<script>
export default {
  name: "cjwt",
  data(){
    return{
      msgList:[],
      page:1,
      pageSize:10,
      total:0
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      this.$get('/api/articles',{page:this.page,limit:this.pageSize}).then(res=>{
        this.total = res.data.total;
        this.msgList=res.data.list
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    goDetails(id){
      this.$router.push('/details?id='+id)
    }
  }
}
</script>

<style scoped lang="scss">
   .cjwt{
     width: 1200px;
     margin: 60px auto 32px;
     .title{
       font-size: 24px;
       font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
       font-weight: 500;
       color: #333333;
     }
     .item-content{
       flex-wrap: wrap;
       margin-top: 36px;
       .item{
         width: 588px;
         height: 142px;
         background: #FFFFFF;
         padding: 29px 25px;
         opacity: 1;
         margin-bottom: 21px;
         border-radius: 16px;
         &:hover{
           cursor: pointer;
           box-shadow: 0px 4px 10px 0px rgba(255,5,55,0.149);
         }
         .s-title{
           font-size: 20px;
           font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
           font-weight: 500;
           color: #333333;
           img{
             width: 30px;
             height: 30px;
             margin-right:14px
           }
         }
         .btn{
           text-align: right;
           margin-top: 18px;
           div{
             display: inline-block;
             padding: 0;
             width: 106px;
             line-height: 38px;
             background: #F42323;
             border-radius: 50px 50px 50px 50px;
             font-size: 16px;
             font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
             font-weight: 400;
             color: #FFFFFF;
             text-align: center
           }
         }
       }
     }
    .page-view{
      text-align: right;
    }
   }
</style>
