<template>
  <div id="app" ref="scrollview">
    <header-bar></header-bar>
    <router-view class="router-view" ></router-view>
    <footer-bar></footer-bar>
    <side-nav v-show="scrollTop>350" @toPageTop="toPageTop"></side-nav>
    <login-model></login-model>
    <pay-model></pay-model>
  </div>
</template>

<script>
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import SideNav from "@/common/SideNav";
import LoginModel from "@/common/LoginModel";
import PayModel from "@/common/PayModel";
export default {
  name: 'App',
  components: {
    HeaderBar,FooterBar,SideNav,
    LoginModel,PayModel
  },
  data(){
    return{
      top:0,
      scrollTop:0
    }
  },
  mounted(){
    window.addEventListener("scroll", this.getScroll);
    this.checkLogin()
  },
  methods:{
    getScroll(){
     // console.log(e)
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop=scrollTop
    },
    toPageTop(){
       this.scrollTop=0;
      document.documentElement.scrollTop=0
    },
    checkLogin() {
      this.$get('/api/authorizations/check').then(() => {
        this.$get('/api/user').then(res=>{
          this.setUserInfos(res.data)
        })
      })
    }
  }
}
</script>

<style lang="scss">
*{
  box-sizing: border-box;
}
#app{
  background-color: #F6F6F6;
  color:#333333;
  el-button{
   border:none
  }
  .empty{
    text-align: center;
    padding-top: 100px;
    img{
      width: 426px;
      height: 426px;
    }
  }
  .common-content{
    width: 1200px;
    margin:35px auto;
    padding: 48px;
    background-color: #fff;
    border-radius: 16px;
    .title{
      font-size: 24px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    .info{
      font-size: 16px;
      font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
      font-weight: 400;
      color: #333333;
      /deep/ img{
        max-width: 100% !important;
      }
    }
  }
.flex{
  display: flex
}
.flex-items{
  display: flex;
  align-items: center;
}
.flex-center{
  justify-content: center;
}
.flex-between{
  justify-content: space-between;
}
.flex-around{
  justify-content: space-around;
}

}
</style>
