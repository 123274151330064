<template>
  <div class="details-page">
    <div class="title flex-items">
      <img src="../../assets/images/wen.png" alt="">
      <div>{{info.title}}</div>
    </div>
    <div class="info" >
      <div class="da">答</div>
      <div class="content" v-html="info.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "details",
  data(){
    return{
      info:{}
    }
  },
  mounted(){
    let id=this.$route.query.id;
    this.getInfo(id)
  },
  methods:{
    getInfo(id){
      this.$get('/api/article/'+id).then(res=>{
        this.info=res.data
      })
    }
  }
}
</script>

<style  lang="scss">
   .details-page{
     width: 1200px;
     margin:32px auto;
     background-color: #fff;
     border-radius: 16px;
     padding:52px 52px 52px 43px;
     .title{
       font-size: 24px;
       font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
       font-weight: 500;
       color: #333333;
       img{
         width: 30px;
         height: 30px;
         margin-right:14px
       }
     }
     .info{
       display: flex;
       margin-top: 32px;
       .da{
         width: 30px;
         height: 30px;
         line-height: 30px;
         background-image: url('../../assets/images/da.png');
         background-size: 100% 100%;
         font-size: 16px;
         font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
         font-weight: 700;
         color: #404B7C;
         text-align: center;

       }
       .content{
         flex:1;
         margin-left: 16px;
          p{
           margin-top:0
         }

       }

     }
   }
</style>
