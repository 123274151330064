<template>
  <div class="buy-records">
    <div class="title-content">
      <div class="title">购买记录</div>
      <div class="line"></div>
    </div>
    <div class="record-content">
      <div class="product-content" v-if="total>0">
        <div class="product-items">
          <div class="items" v-for="(item,index) in productList" :key="index">
            <div class="order-no flex-items">
              <span>
                订单编号：{{ item.order_no }}
              </span>
              <span>支付时间：{{ item.pay_time }}</span>
            </div>
            <div class="product flex-items">
<!--              <div class="name flex-items flex-center">-->
<!--                {{item.name}}-->
<!--              </div>-->
              <img class="product-image" :src="item.image" alt="">
              <div class="infos">
                <div class="title">{{item.title}}</div>
                <div class="intro">{{item.info}}</div>
                <div class="date flex-items">
                  <div>{{item.date}}</div>
                  <div class="line"></div>
                  <div>{{item.address}}</div>
                </div>
              </div>
              <div class="buy">
                <div class="price">￥{{item.money}}</div>
                <el-button class="btn" @click="goPay(item.id,item.order_no)" v-if="item.pay==0">去支付</el-button>
                <el-button v-else-if="item.pay==1"  class="btn" @click="downloadUrl(item.id)">下载资料</el-button>
                <el-button class="btn"  v-else>已过期</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="page-view">
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              background
              layout="total,sizes,prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>

      </div>
      <div class="empty" v-if="hasData&&productList.length==0">
        <img src="../../../assets/images/empty.png" alt="">
      </div>
    </div>
    <div id="alipay">

    </div>
    <div class="wx-model">
      <el-dialog  :visible.sync="showQrModel"
                  width="528px"
                  center @close="showQrModel=false">
        <div class="content">
          <div id="qrcode" ref="qrcode" class="flex-items flex-center" style="margin:0 auto;"></div>
          <div class="tips flex-items flex-center">
            <img src="../../../assets/images/wx-tip.png" alt="">
          </div>
          <div class="qr-btns flex-items flex-center">
            <el-button @click="showQrModel=false">取消</el-button>
            <el-button type="primary" @click="checkOrder">确认支付</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
// import download from "downloadjs";
import QRCode from 'qrcodejs2'
export default {
  name: "buyRecords",
  data(){
    return{
      productList:[],
      total:0,
      pageSize:10,
      page:1,
      hasData:false,
      showQrModel:false,
      qrcode: null,
      showAlipay:false,
      aliUrl:'',
      order_no:''
    }
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      this.$get('/api/order',{page:this.page,limit:this.pageSize}).then(res=>{
        this.total = res.data.total;
        this.productList=res.data.list;
        this.hasData=true
      }).catch(()=>{
        this.hasData=false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    goPay(id,order_no){
      let that=this;
      this.$get('/api/order/pay',{id:id}).then(res=>{
        this.order_no=res.data.order_no
        if (res.data.pay_method==0){
          document.querySelector("#alipay").innerHTML = res.data.pay_address;
          document.forms[0].submit();
          setTimeout(()=>{
            this.$confirm('请在新打开的页面完成支付后，根据支付结果点击下面的按钮?', '是否完成支付', {
              confirmButtonText: '支付完成',
              cancelButtonText: '取消',
              type: 'info'
            }).then(() => {
               this.checkOrder(order_no)
            }).catch(() => {

            });
          },2000)
        }else{
          this.showQrModel=true;
          if (this.qrcode!=null) {
            this.qrcode.clear() // 清除原来的二维码
            this.$refs.qrcode.innerHTML=''
          }
          that.$nextTick(() => {
            this.qrcode = new QRCode('qrcode', {
              width: 200,
              height: 200,
              text: res.data.pay_address
            })
          });


        }
      })
    },
    checkOrder(){
      this.$get('/api/pay/check',{order_no:this.order_no}).then(res=>{
        this.showQrModel=false
        if (res.data.pay==1){
          this.$message.success(res.msg)

          this.getList()
        }else{
          this.$message.error('支付失败')
        }

      })
    },
    downloadUrl(id){
      this.$get('/api/download',{order_id:id}).then(res=>{
        //let url=res.data.download_url;
        let pathUrl=res.data.download_url;
       //   let path=window.location.protocol+'//'+pathUrl
      //  window.location.href=pathUrl
      // window.open(path, '_blank')
        const newWindow = window.open('about:blank', '_blank');
        newWindow.location.href=pathUrl;
        // 销毁添加的a标签
        setTimeout(() => {
          newWindow.close()
        },5000);// 5000 毫秒后关闭窗口

      }).catch((err)=>{
       // console.log(err)
          this.$alert(err.data.msg, '提示', {
            confirmButtonText: '确定',
            callback: () => {

            }
          })

      })
    }
  }
}
</script>

<style scoped lang="scss">
  .buy-records{
    .product-content{
      background: #FFFFFF;
      border-radius: 10px;
      padding:42px 23px 10px;
      .product-items{
        .items{
          box-shadow: 0px 4px 10px 0px rgba(255,5,55,0.149);
          border: 1px solid #E5E5E5;
          margin-bottom: 21px;
        }
        .order-no{
          height: 34px;
          background: #F6F6F6;
          padding-left: 17px;
          opacity: 1;
          border: 1px solid #F6F6F6;
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          color: #9E9E9E;
          span:last-child{
            margin-left: 50px;
          }
        }
        .product{
          padding:20px;

          .product-image{
            width: 138px;
            height: 138px;
            border-radius: 10px 10px 10px 10px;
            object-fit: cover;
          }
          .name{
            width: 138px;
            height: 138px;
            background: #E93A13;
            border-radius: 10px 10px 10px 10px;
            text-align: center;
            font-size: 24px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 700;
            color: #FFFFFF;
          }
          .infos{
            margin:0 22px;
            flex:1;
            .title{
              font-size: 20px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
            }
            .intro{
              margin-top:16px;
              font-size: 14px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
              color: #9E9E9E;
            }
            .date{
              font-size: 14px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
              color: #9E9E9E;
              margin-top:30px;
              .line{
                width: 2px;
                height: 15px;
                background-color: #9E9E9E;
                margin: 0 10px;
              }
            }
          }
          .buy{
            display: flex;
            flex-direction: column;
            align-items: center;
            .price{
              font-size: 24px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
              color: #F42323;
            }
            .btn{
              width: 128px;
              line-height: 46px;
              text-align: center;
              background: #F42323;
              border-radius: 50px;
              margin-top: 17px;
              color: #fff;
              font-size: 18px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 400;
              padding:0;
            }
          }
        }
      }
      .page-view{
        text-align: right;
      }
    }
    .wx-model{
      .content{
        .tips{
          margin-top: 10px;
          img{
            width: 200px
          }
        }
        .qr-btns{
          margin-top: 20px;
        }
      }
    }
  }
</style>
