import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        sum: 0,
        school:"尚硅谷",
        subject:"前端",
        loginModel:false,
        loginType:3,
        token:'',
        userInfo:{},
        payStatus:false,
        payId:'',
        baseInfo:{}

    },
    mutations: {
        setLogin(state, value) {
            state.loginModel = value
        },
        setLoginType(state, value) {
            state.loginType = value
        },
        setToken(state,value){
            state.token=value
        },
        setUserInfo(state,value){
            state.userInfo=value
        },
        setPay(state,value){
            state.payStatus=value
        },
        setPayId(state,value){
            state.payId=value
        },
        setConfig(state,value){
            state.baseInfo=value
        }
    },
    plugins: [createPersistedState()]
})
