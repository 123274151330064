<template>
  <div class="pay-model">
    <el-dialog
        :visible.sync="showModel"
        width="528px"
        center @close="closePayModel">
         <div class="content">
            <div class="title">付款方式</div>
           <div class="flex-items flex-center">
                <div class="pay-item flex-items flex-center" @click="chooseTab(0)" :class="tabId===0?'active':''">
                  <img src="../assets/images/ali.png" alt="">
                  <div>支付宝</div>
                </div>
             <div class="pay-item flex-items flex-center"  @click="chooseTab(1)" :class="tabId==1?'active':''">
               <img src="../assets/images/wx.png" alt="">
               <div>微信支付</div>
             </div>
           </div>
           <div class="btn">
             <el-button @click="closePayModel">取 消</el-button>
             <el-button class="pay"  @click="goPay">立即支付</el-button>
           </div>
         </div>
      <!--    <el-button @click="closeLoginModel">取 消</el-button>-->
      <!--    <el-button type="primary" @click="closeLoginModel">确 定</el-button>-->

    </el-dialog>
    <div class="wx-model">
      <el-dialog  :visible.sync="showQrModel"
                  width="528px"
                  center @close="showQrModel=false">
        <div class="content">
          <div id="qrcode" ref="qrcode" class="flex-items flex-center" style="margin:0 auto;"></div>
          <div class="tips flex-items flex-center">
            <img src="../assets/images/wx-tip.png" alt="">
          </div>
          <div class="qr-btns flex-items flex-center">
            <el-button @click="cancelOrder">取消</el-button>
            <el-button type="primary" @click="checkOrder">确认支付</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div id="alipay">

    </div>

  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: "PayModel",
  computed:{
    showModel:{
      get(){
        return this.$store.state.payStatus
      },
      set(){

      }
      },


    payId(){
      return this.$store.state.payId
    }
  },
  data(){
    return{
      tabId:'',
      showQrModel:false,
      qrcode: null,
      showAlipay:false,
      aliUrl:'',
      order_no:''
    }
  },
  created(){

  },
  methods:{
    chooseTab(id){
      this.tabId=id
    },
    checkOrder(){

      this.$get('/api/pay/check',{order_no:this.order_no}).then(res=>{
        this.showQrModel=false
        this.closePayModel()
        if (res.data.pay==1){
          this.$message.success(res.msg)
        }else{
          this.$message.error('支付失败')
        }
      })
    },
    cancelOrder(){
      this.showQrModel=false
      this.closePayModel()
    },
    goPay(){
      let that=this;
        if (this.tabId===''){
          return this.$message.error('请选择支付方式')
        }
        this.$post('/api/order',{id:this.payId, pay_method:this.tabId}).then(res=>{
          this.order_no=res.data.order_no
          if (this.tabId==1){
            this.showQrModel=true;
            if (this.qrcode!=null) {
              this.qrcode.clear() // 清除原来的二维码
              this.$refs.qrcode.innerHTML=''
            }
            that.$nextTick(() => {
              this.qrcode = new QRCode('qrcode', {
                width: 200,
                height: 200,
                text: res.data.pay_address
              })
            });
          }else{
            this.closePayModel()
            document.querySelector("#alipay").innerHTML = res.data.pay_address;
            document.forms[0].submit();
            setTimeout(()=>{
              this.$confirm('请在新打开的页面完成支付后，根据支付结果点击下面的按钮?', '是否完成支付', {
                confirmButtonText: '支付完成',
                cancelButtonText: '取消',
                type: 'info'
              }).then(() => {
                this.checkOrder(res.data.order_no)
              }).catch(() => {

              });
            },2000)
          }
        })

    }
  }
}
</script>

<style lang="scss">
 .pay-model{
   .el-dialog{
     width: 528px;
     //height: 618px;
     background: #FFFFFF;
     border-radius: 20px;
     .content{
       .title{
         text-align: center;
         font-size: 24px;
         font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
         font-weight: 500;
         color: #333333;
         margin-bottom: 32px;
       }
       .pay-item{
         width: 150px;
         height: 50px;
         font-size: 18px;
         font-weight: 450;
         margin-right: 30px;
         line-height: 50px;
         border: 1px solid #C4C4C4;
         border-radius: 5px;
         cursor: pointer;
         text-align: center;
         img{
           width:30px;
           margin-right: 10px
         }

       }
       .active{
         background: url("../assets/images/pay-status.png") no-repeat 100% 100%;
         border: 1px solid #77BF64;
       }
       .btn{
         text-align: right;
         margin-top: 50px;
         .el-button{
         }
         .pay{

           background: #F42323;
           color: #fff
         }
       }

     }

   }
   .wx-model{
     .content{
       .tips{
         margin-top: 10px;
         img{
           width: 200px
         }
       }
       .qr-btns{
         margin-top: 20px;
       }
     }
   }
 }
</style>
