<template>
   <div class="header-bar">
     <div class="header">
          <img class="logo" @click="toIndex" :src="baseInfo.logo" alt="">
        <div class="nav-btns flex-items" v-if="token">
          <div @click="navTo('/')">首页</div>
          <div @click="navTo('/buyRecords')">购买记录及下载</div>
          <div @click="navTo('/userInfo')">个人中心</div>
        </div>
       <div class="login-btns">
         <div class="msg" v-if="token" @click="navTo('/userMsg')">
           <img src="../assets/images/ling.png" alt="">
           <span>我的消息</span>
           <i v-if="userInfo.unread_message>0"></i>
         </div>
         <div v-if="token" class="user-icon" @click="goInfo">
           <img :src="userInfo.avatar" alt="">
           <span>{{ userInfo.nickname }}</span>
           <span class="logout" @click.stop="logOut">退出</span>
         </div>
         <el-button v-else class="login" @click="showLoginModel">
           注册/登录
         </el-button>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "HeaderBar",
  computed:{
    token(){
      return this.$store.state.token
    },
    userInfo(){
      return this.$store.state.userInfo
    },
    baseInfo(){
      return this.$store.state.baseInfo
    }
  },
  mounted(){
   // this.getUserInfo()
  },
  methods:{
    getUserInfo(){
      if (this.token){
        this.$get('/api/user').then(res=>{
          this.setUserInfos(res.data)
        })
      }

    },
    goInfo(){
      this.$router.push('/userInfo')
    },
    toIndex(){
      let route=this.$route.path;
      if (route!='/'){
        this.navTo("/")
      }
    },
    logOut(){
      this.$confirm('您确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '您已退出登录!'
        });
        this.toIndex()
        this.logOutStatus()
      }).catch(() => {

      });
    }
  }
}
</script>

<style scoped lang="scss">
   .header-bar{
     height:64px;
     background-color: #fff;
     .header{
       width:1200px;
       height: 100%;
       display: flex;
       justify-content: space-between;
       align-items: center;
       margin: 0 auto;
       .logo{
          width: 158px;
         cursor: pointer;
       }
       .nav-btns{
         div{
           padding: 10px 20px;
           cursor: pointer;
           &:hover{
             color: #f82222;
           }
         }
       }
       .login-btns{
         display: flex;
         align-items: center;
         .msg{
           display: flex;
           align-items: center;
           height: 32px;
           border-radius: 22px;
           opacity: 1;
           border: 1px solid #E5E5E5;
           padding:0 16px 0 11px;
           position: relative;
           cursor: pointer;
           img{
             width:22px;
             height: 22px;
             margin-right: 3px
           }
           i{
             position: absolute;
             top:-2px;
             right: 2px;
             width: 10px;
             height: 10px;
             background: #F42323;
             border-radius: 50%;
           }
         }
         .user-icon{
           display: flex;
           align-items: center;
           margin-left: 30px;
           cursor: pointer;
           img{
             width:46px;
             height: 46px;
             margin-right: 5px;
             object-fit: cover;
           }
           .logout{
             margin-left: 10px;
             width: 57px;
             color: #ffffff;
             font-size: 16px;
             text-align: center;
             line-height: 27px;
             background: #BBBBBB;
             border-radius: 14px;

           }
         }
         .login{
           background-color: #F42323;
           line-height: 32px;
           padding:0 16px;
           color: #ffffff;
           border-radius: 40px;
           font-size: 16px;
           font-weight: 400;
           margin-left: 30px
         }
       }

     }
   }
</style>
