import Vue from 'vue'
import Router from 'vue-router'
import index from '@/views/index'
import userCenter from "@/views/userCenter/userCenter"
import userInfo from "@/views/userCenter/details/userInfo"
import buyRecords from "@/views/userCenter/details/buyRecords";
import invoiceRecords from "@/views/userCenter/details/invoiceRecords";
import userMsg from "@/views/userCenter/details/userMsg";
import ptkf from "@/views/userCenter/details/ptkf";
import suggestion from "@/views/userCenter/details/suggestion";
import cjwt from "@/views/details/cjwt";
import aboutUs from "@/views/details/aboutUs";
import privacy from "@/views/details/privacy";
import shgz from "@/views/details/shgz";
import projectDetails from "@/views/userCenter/details/projectDetails";
import details from "@/views/details/details"
import userAgreement from "@/views/details/userAgreement";
Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		// {
		//   path: '/',
		//   name: 'goPayment',
		//   component: goPayment,
		//   meta: {
		//     tx:1
		//   }
		// },
		{
			path: '/',
			name: 'index',
			component: index,
		},
		{
			path: '/shgz',
			name: 'shgz',
			component: shgz,
		},
		{
			path: '/aboutUs',
			name: 'aboutUs',
			component: aboutUs,
		},
		{
			path: '/privacy',
			name: 'privacy',
			component: privacy,
		},
		{
			path: '/cjwt',
			name: 'cjwt',
			component: cjwt,
		},
		{
			path: '/projectDetails',
			name: 'projectDetails',
			component: projectDetails,
		},
		{
			path: '/details',
			name: 'details',
			component: details,
		},
		{
			path: '/userAgreement',
			name: 'userAgreement',
			component: userAgreement,
		},

		{
			path: '/userCenter',
			name: 'userCenter',
			component: userCenter,
			children: [{
					path: '/userInfo',
					name: 'userInfo',
					component: userInfo,
				},
				{
					path: '/buyRecords',
					name: 'buyRecords',
					component: buyRecords,
				},

				{
					path: '/invoiceRecords',
					name: 'invoiceRecords',
					component: invoiceRecords,
				},
				{
					path: '/userMsg',
					name: 'userMsg',
					component: userMsg,
				},
				{
					path: '/ptkf',
					name: 'ptkf',
					component: ptkf,
				},
				{
					path: '/suggestion',
					name: 'suggestion',
					component: suggestion,
				}
			]
		}
	]
})