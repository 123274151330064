import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router'
import mixin from './mixin'
import {
  instance,
  http
} from './request/https'
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import '../theme/index.css'
Vue.use(ElementUI,{ size: 'small' })
Vue.mixin(mixin)
// 定义全局变量
Vue.prototype.$post = http.post;
Vue.prototype.$get = http.get;
Vue.prototype.$put = http.put;
Vue.prototype.$axios = instance;
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
