<template>
	<div class="user-center">
		<div class="user-container flex">
			<div class="left-content">
				<div class="left-item" @click="navTo('/userInfo')" :class="$route.path=='/userInfo'?'active':''">个人信息
				</div>
				<div class="left-item" @click="navTo('/buyRecords')" :class="$route.path=='/buyRecords'?'active':''">
					购买记录</div>
				<div class="left-item" @click="navTo('/invoiceRecords')"
					:class="$route.path=='/invoiceRecords'?'active':''">开票记录</div>
				<div class="left-item" @click="navTo('/userMsg')" :class="$route.path=='/userMsg'?'active':''">我的消息
				</div>
				<div class="left-item" @click="navTo('/ptkf')" :class="$route.path=='/ptkf'?'active':''">平台客服</div>
				<div class="left-item" @click="navTo('/suggestion')" :class="$route.path=='/suggestion'?'active':''">
					意见反馈</div>
			</div>
			<div class="right-content">
				<router-view class="router-view"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "userCenter",
		data() {
			return {
				routeName: ''
			}
		},
		mounted() {
			this.routeName = this.$route.path
		},
		methods: {
			getUrl() {
				this.routeName = this.$route.path
			}
		}
	}
</script>

<style lang="scss">
	.user-center {
		padding: 37px 0 28px;

		.user-container {
			width: 1200px;
			margin: 0 auto;

			.left-content {
				width: 269px;
				height: 645px;
				background: #FFFFFF;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding-top: 47px;

				.left-item {
					cursor: pointer;
					width: 220px;
					line-height: 55px;
					padding-left: 28px;
					font-size: 18px;
					font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
					font-weight: 500;
					color: #333333;
					background-color: #F6F6F6;
					border-radius: 32px;
					margin-bottom: 26px;
				}

				.active {
					background: #FFE0E0;
					color: #F42323
				}
			}

			.right-content {
				flex: 1;
				min-height: 645px;
				background: #FFFFFF;
				border-radius: 10px;
				margin-left: 17px;
				padding: 20px 0;

				.title-content {
					border-bottom: 1px solid #D8D8D8;
					display: flex;
					flex-direction: column;
					padding-left: 34px;

					.title {
						font-size: 20px;
						font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
						font-weight: 500;
						color: #333333;
					}

					.line {
						margin-top: 10px;
						height: 4px;
						width: 89px;
						background-color: #F42323;
						border-radius: 50px
					}
				}

				.page-view {
					text-align: right;
				}
			}
		}
	}
</style>