<template>
   <div class="project-details">
      <div class="details-content">
        <div class="product-content">
          <div class="flex-items">
            <div class="title">{{info.title}}</div>
            <div class="price">￥{{info.price}}</div>
          </div>
          <div class="product flex-items" >
<!--            <div class="name flex-items flex-center">-->
<!--              {{info.name}}-->
<!--            </div>-->
            <img class="product-image" :src="info.image" alt="">
            <div class="infos">
              <div class="intro">{{info.describe}}</div>
              <div class="date flex-items">
                <div>{{info.date}}</div>
                <div class="line"></div>
                <div>{{info.address}}</div>
              </div>
            </div>
            <div class="buy">
              <el-button class="btn" @click="showPayModel(info.id)">购买</el-button>
            </div>
          </div>
        </div>
        <div class="details-info">
          <div class="title-content">
            <div class="title">详情介绍</div>
            <div class="line"></div>
          </div>
          <div class="infos">
             <div v-html="info.content"></div>
          </div>
        </div>
      </div>
   </div>
</template>

<script>
export default {
  name: "projectDetails",
  data(){
    return{
      info:{
      },
    }
  },
  mounted(){
    let id=this.$route.query.id;
    this.getInfo(id)
  },
  methods:{
    getInfo(id){
      this.$get('/api/project/'+id).then(res=>{
        this.info = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
   .project-details{
     background-color: #FFE0E0;
     padding: 35px 0;
     .details-content{
       .product-content{
         width: 1200px;
         background: #FFFFFF;
         border-radius: 10px;
         margin: 0 auto;
         padding:46px 33px;
         .title{
           flex:1;
           font-size: 36px;
           font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
           font-weight: 500;
           color: #333333;
         }
         .price{
           font-size: 24px;
           font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
           font-weight: 500;
           color: #F42323;
         }
         .product{
           margin-top: 30px;
           cursor: pointer;
           .product-image{
             width: 138px;
             height: 138px;
             border-radius: 10px 10px 10px 10px;
             object-fit: cover;
           }
           .name{
             width: 138px;
             height: 138px;
             background: #E93A13;
             border-radius: 10px 10px 10px 10px;
             text-align: center;
             font-size: 24px;
             font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
             font-weight: 700;
             color: #FFFFFF;
           }
           .infos{
             margin:0 22px;
             flex: 1;

             .intro{
               font-size: 14px;
               font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
               font-weight: 500;
               color: #9E9E9E;
               line-height: 32px;
             }
             .date{
               font-size: 14px;
               font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
               font-weight: 500;
               color: #9E9E9E;
               margin-top:30px;
               .line{
                 width: 2px;
                 height: 15px;
                 background-color: #9E9E9E;
                 margin: 0 10px;
               }
             }
           }
           .buy{
             display: flex;
             flex-direction: column;
             align-items: center;

             .btn{
               width: 128px;
               line-height: 46px;
               text-align: center;
               background: #F42323;
               border-radius: 50px;
               margin-top: 17px;
               color: #fff;
               font-size: 18px;
               font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
               font-weight: 400;
               padding:0;
             }
           }
         }
         .page-view{
           text-align: right;
         }
       }
       .details-info{
         width: 1200px;
         margin: 20px auto 0;
         background-color: #fff;
         border-radius: 10px;
         padding: 20px 0;
         .title-content{
           border-bottom: 1px solid #D8D8D8;
           display: flex;
           flex-direction: column;
           padding-left: 34px;
           .title{
             font-size: 20px;
             font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
             font-weight: 500;
             color: #333333;
           }
           .line{
             margin-top:10px;
             height: 4px ;
             width:89px;
             background-color: #F42323;
             border-radius:50px
           }
         }
         .infos{
           margin: 32px 149px;
           border: 1px solid #999;
           padding: 32px;
           /deep/ img{
             max-width: 100% !important;
           }
         }
       }
     }
   }
</style>
