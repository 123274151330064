<template>
  <div class="main" >
    <div class="index-container">
      <div class="index-bg" :style="{backgroundImage:'url('+baseInfo.banner+')'}">
<!--        <div class="title">文件下载资料</div>-->
<!--        <div class="sub-title">-->
<!--          文字小标题文字小标题文字小标题文字小标题-->
<!--        </div>-->
        <div class="search-bar">
          <img src="../assets/images/search.png" alt="">
          <input type="text" v-model="keyWord"  placeholder="请输入关键词进行搜索"/>
          <div class="search-btn" @click="search">搜索</div>
        </div>
      </div>
      <div class="sx-content">
        <div class="sx-title flex-items flex-between">
          <div class="title">筛选条件</div>
          <div class="flex-items">
            <div class="search-s" @click="search2">确定</div>
            <div class="reset" @click="reset">重置</div>
          </div>

        </div>
        <div class="sx-items flex-items">
          <div class="s-title">时间筛选：</div>
          <div>
            <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="选择开始日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="margin-right: 13px">
            </el-date-picker>
            <el-date-picker
                v-model="endDate"
                type="date"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                placeholder="选择结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="sx-items flex" style="margin-bottom:4px">
          <div class="s-title">类型筛选：</div>
          <div class="items flex-items">
            <div class="item " :class="itemId==item.id?'active':''" v-for="(item,index) in itemList" :key="index" @click="chooseItem(item.id)">{{item.name}}</div>
          </div>
        </div>
        <div class="sx-items flex-items">
          <div class="s-title">区域筛选：</div>
          <div>
            <el-select v-model="provinceId" @change="chooseProvince" placeholder="请选择" style="margin-right: 13px">
              <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id" >
              </el-option>
            </el-select>
            <el-select v-model="cityId" @change="chooseCity" placeholder="请选择" style="margin-right: 13px">
              <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
<!--            <el-select v-model="areaId" placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in areaList"-->
<!--                  :key="item.id"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--              </el-option>-->
<!--            </el-select>-->
          </div>
        </div>
      </div>
      <div class="product-content" v-if="total>0">
        <div class="product-items">
          <div class="product flex-items" v-for="(item,index) in productList" :key="index" @click="goDetails(item.id)">
<!--            <div class="name flex-items flex-center">-->
<!--              {{item.name}}-->
<!--            </div>-->
            <img class="product-image" :src="item.image" alt="">
            <div class="infos">
              <div class="title">{{item.title}}</div>
              <div class="intro">{{item.describe}}</div>
              <div class="date flex-items">
                <div>{{item.date}}</div>
                <div class="line"></div>
                <div>{{item.address}}</div>
              </div>
            </div>
            <div class="buy">
              <div class="price">￥{{item.price}}</div>
              <el-button class="btn" @click.stop="showPayModel(item.id)">购买</el-button>
            </div>
          </div>
        </div>
        <div class="page-view">
          <el-pagination
              :current-page="page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              background
              layout="total,sizes,prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>

      </div>
      <div class="empty" v-if="hasData&&productList.length==0">
        <img src="../assets/images/empty.png" alt="">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      startDate:'',
      endDate:'',
      keyWord:'',
      total:0,
      page:1,
      pageSize:10,
      itemList:[

      ],
      productList:[

      ],
      provinceList:[],
      cityList:[],
      areaList:[],
      itemId:'',
      provinceId:'',
      cityId:'',
      areaId:'',
      hasData:false,

    }
  },
  computed:{
    baseInfo(){
      return this.$store.state.baseInfo
    }
  },
  created(){
    this.getConfig()
  },
  mounted(){

    let token = localStorage.getItem('token');
    if (!token){
      // this.$router.push('/login')
    }else{
      // let userInfo=localStorage.getItem("userInfo")
      // this.userInfo=JSON.parse(userInfo)
      // this.getList()
    }

    this.getList();
    this.getprovince()
    this.getCategory()
  },
  methods:{
    search(){
      this.page = 1;
      let params={
        keyword:this.keyWord,
        category_id:this.itemId,
        province_id:this.provinceId,
        city_id:this.cityId,
        // district_id:this.areaId,
        start_date:this.startDate,
        end_date:this.endDate,
        limit:this.pageSize,
        page:this.page
      };
      this.getList(params)
    },
    search2(){
      this.page = 1;
      let params={
        category_id:this.itemId,
        province_id:this.provinceId,
        city_id:this.cityId,
        // district_id:this.areaId,
        start_date:this.startDate,
        end_date:this.endDate,
        limit:this.pageSize,
        page:this.page
      };
      this.getList(params)
    },
    getConfig(){
      this.$get('/api/config',{}).then(res=>{
        this.$store.commit("setConfig", res.data);
      })
    },
    showPop(url){
      this.$dialog.alert({
        message:url
      })
    },
    showContent(content){
      this.$dialog.alert({
        message:content
      })
    },
    chooseItem(id){
      if (id ==this.itemId){

        this.itemId=''
      }else{
        this.itemId=id
      }
    },
    getList(data){
      let params={
        limit:this.pageSize,page:this.page
      }
      if (data){
        params = data
      }
      this.$get('/api/project',params).then(res=>{
        this.total = res.data.total;
        this.productList=res.data.list
        this.hasData=true
      }).catch(()=>{
        this.hasData=false
      })
    },
    getCategory(){
     this.$get('/api/category').then(res=>{
        this.itemList=res.data
     })
    },
    chooseProvince(e){
      this.getCity(e)


    },
    chooseCity(e){
      this.getArea(e)
    },
    getprovince(){
      this.$get('/api/address').then(res=>{
        this.provinceList=res.data
      })
    },
    getCity(id){
      this.$get('/api/address',{parent_id:id}).then(res=>{
        this.cityList=res.data
        this.search2()
      })


    },
    getArea(id){
      this.$get('/api/address',{parent_id:id}).then(res=>{
        this.areaList=res.data
        this.search2()
      })

    },
    reset(){
      this.areaId='';
      this.provinceId='';
      this.cityId='';
      this.itemId='';
      this.startDate='';
      this.endDate='';
      this.keyWord='';
      this.page = 1;
      this.getList()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    confirmUrl(id){
      // this.$api.setUrl({id:id}).then(res=>{
      //   if (res.success){
      //     this.$toast.success('操作成功')
      //     this.getList()
      //   }
      // })
      this.$router.push('/Sign?id='+id)
    },
    goDetails(id){
      this.$router.push('/projectDetails?id='+id)
    },
    logout(){
      this.$dialog.confirm({
        title:'提示',
        message:'您确定要退出登陆吗？'
      }).then(()=>{
        localStorage.setItem('token','')
        this.$router.push('/login')
      }).catch(()=>{

      })

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main{
  padding-bottom:76px;
  .index-container{
    .index-bg{
      height:411px;
      width: 100%;
      //background-image: url('../assets/images/index-top-bg.png');
      background-size:100% 100% ;
      position: relative;

      .title{
        font-size: 64px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 700;
        color: #FFFFFF;
        text-align: center;
        padding-top: 70px;
      }
      .sub-title{
        font-size: 24px;
        font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        margin: 40px 0;
      }
      .search-bar{
        position: absolute;
        top: 264px;
        left:0;
        right: 0;
        width:945px;
        height:60px;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 40px;
        margin: 0 auto;
        input{
          flex:1;
          border:none;
          margin-right: 23px;
          font-size: 20px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          color: #BBBBBB;
        &:focus-visible{
          border:none;
          outline:0
        }
        }
        img{
          width:26px;
          height:26px;
          margin:0 15px 0 23px
        }
        .search-btn{
          width:155px;
          line-height: 60px;
          background-color: #FFCCCC;
          text-align: center;
          border-radius: 40px;
          cursor: pointer;
          font-size: 24px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          color: #F42323;
        }
      }
    }
    .sx-content{
      width: 1200px;
      height: 252px;
      background: #FFFFFF;
      border-radius: 10px;
      margin: -40px auto 0;
      position: relative;
      padding:28px 33px;
      .sx-title{
        margin-bottom: 30px;
        .title{
          font-size: 20px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          color: #333333;
        }
        .search-s{
          width: 48px;
          line-height: 25px;
          background: #F42323;
          border-radius: 20px;
          text-align: center;
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          color: #fff;
          margin-right: 10px;
          &:hover{
            cursor: pointer;
          }
        }
        .reset{
          width: 48px;
          line-height: 25px;
          background: #F6F6F6;
          border-radius: 20px;
          text-align: center;
          font-size: 14px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 400;
          color: #999999;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .sx-items{
        margin-bottom: 21px;

        .s-title{
          line-height: 32px
        }
        .items{
          flex:1;
          .item{
            width: 80px;
            line-height: 32px;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            margin-right: 13px;
            margin-bottom: 13px;
            cursor: pointer;
          }
          .active{
            border-color: #FFE0E0;
            background-color: #FFE0E0;
            color: #f82222
          }
        }
      }
    }
    .product-content{
      width: 1200px;
      background: #FFFFFF;
      border-radius: 10px;
      margin: 17px auto 0;
      padding:46px 33px;
      .product-items{
        .product{
          padding-bottom:19px;
          border-bottom: 1px solid #E5E5E5;
          margin-bottom: 21px;
          cursor: pointer;
          .product-image{
            width: 138px;
            height: 138px;
            border-radius: 10px 10px 10px 10px;
            object-fit: cover;
          }
          .name{
            width: 138px;
            height: 138px;
            background: #E93A13;
            border-radius: 10px 10px 10px 10px;
            text-align: center;
            font-size: 24px;
            font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
            font-weight: 700;
            color: #FFFFFF;
          }
          .infos{
            margin:0 22px;
            flex: 1;
            .title{
              font-size: 20px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
            }
            .intro{
              margin-top:16px;
              font-size: 14px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
              color: #9E9E9E;
            }
            .date{
              font-size: 14px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
              color: #9E9E9E;
              margin-top:30px;
              .line{
                width: 2px;
                height: 15px;
                background-color: #9E9E9E;
                margin: 0 10px;
              }
            }
          }
          .buy{
            display: flex;
            flex-direction: column;
            align-items: center;
            .price{
              font-size: 24px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 500;
              color: #F42323;
            }
            .btn{
              width: 128px;
              line-height: 46px;
              text-align: center;
              background: #F42323;
              border-radius: 50px;
              margin-top: 17px;
              color: #fff;
              font-size: 18px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 400;
              padding:0;
            }
          }
        }
      }
      .page-view{
        text-align: right;
      }
    }
  }
}
</style>
