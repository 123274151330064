<template>
   <div class="suggestion">
     <div class="title-content">
       <div class="title">意见反馈</div>
       <div class="line"></div>
     </div>
     <div class="edit-content">
       <div class="upload-item">
         <el-upload
             ref="upload"
             action="https://api.jxmzjzfw.com/api/upload"
             list-type="picture-card"
             :limit="9"
             name="image"
             accpet="'image/jpg,image/jpeg,image/png"
             :on-success="handleImageSuccess"
             :on-preview="handlePictureCardPreview"
             :on-remove="handleRemove"
             :on-error="handleError"
         >
           <div class="up-text">
             <i class="el-icon-plus"></i>
             <span class="tip">添加图片</span>
           </div>

         </el-upload>
         <el-dialog :visible.sync="dialogVisible" size="tiny">
           <img width="100%" :src="dialogImageUrl" alt="">
         </el-dialog>
       </div>
       <div class="edit-text">
         <el-input
             type="textarea"
             placeholder="请输入内容"
             resize="none"
             v-model="text">
         </el-input>
       </div>
       <div class="up-btn">
         <el-button class="btn" :disabled="disable" @click="sub">提交</el-button>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "suggestion",
  data(){
    return{
      dialogImageUrl: '',
      dialogVisible: false,
      text:'',
      imgs:[],
      disable:false
    }
  },
  methods: {
    handleImageSuccess(response) {
      const url = response.data.path
      this.imgs.push(url)
    },
    handleError(){
      this.$message.error('上传失败')

    },
    handleRemove(file) {
      let  imgPath = file.response.data.path;
      let list=this.imgs;
      for (let i=0,len=list.length;i<len;i++){
        if (list[i]==imgPath){
          list.splice(i,1);
          break
        }
      }
      this.imgs = list
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    sub(){
      let imgs=this.imgs;
      let msg=this.text;
      if (!msg){
        return this.$message.error('请输入您的意见')
      }
       this.disable=true
      this.$post('/api/feedback',{images:imgs.join(','),content:msg}).then(res=>{
        this.$message.success(res.msg)
        this.$refs.upload.clearFiles();
        this.text = ''
        this.disable=false
      }).catch(()=>{
        this.disable=false
      })
    }
  }
}
</script>

<style lang="scss">
  .suggestion{
    .edit-content{
      padding: 52px 31px;
      .upload-item{
        .el-upload--picture-card{
          width: 122px;
          height: 122px;
          background: #F6F6F6;
          border: 1px solid #E5E5E5;
          .up-text{
            width: 122px;
            height: 122px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            i{
              color: #F42323;
            }
            .tip{
              height: 22px;
              font-size: 14px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 400;
              color: #BBBBBB;
              line-height: 22px;
            }
          }

        }
        .el-upload-list--picture-card .el-upload-list__item {
          width: 122px;
          height: 122px;
          margin-right: 18px;
        }
      }
      .edit-text{
        margin-top: 25px;

        .el-textarea__inner{
          width: 411px;
          height: 136px;
          background: #FFFFFF;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #E5E5E5;
        }
      }
      .up-btn{
        margin-top: 50px;
        .btn{
          width: 217px;
          height: 55px;
          background: #F42323;
          box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.08);
          border-radius: 28px;
          font-size: 18px;
          font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
          font-weight: 500;
          color: #FFFFFF;
        }

      }
    }
  }
</style>
