export default {
  data(){
    return{

    }
  },
  methods:{
    showLoginModel() {
      this.$store.commit("setLogin", true);
      this.chooseLoginType(3)
    },
    closeLoginModel(){
      this.$store.commit("setLogin", false);
      this.chooseLoginType(3)
    },
    chooseLoginType(type){
      this.$store.commit("setLoginType", type);
    },
    setUserInfos(info){
      this.$store.commit("setUserInfo", info);
    },
    showPayModel(id){
      let token=this.$store.state.token;
      if (!token){
        this.showLoginModel()
      }else{
        this.$store.commit("setPay", true);
        this.$store.commit("setPayId", id);
      }

    },
    closePayModel(){
      this.$store.commit("setPay", false);
    },
    logOutStatus(){
      this.$store.commit("setToken", '');
    },
    regPhone(val) { //手机号码正则
      if ((/^1[3456789]\d{9}$/.test(val))) {
        return true
      }
      return false
    },
    regLoginPwd(val) { //登录密码正则
      if ((/\w{6,18}/.test(val))) {
        return true
      }
      return false
    },
    regCode(val) { //短信验证码正则
      if ((/^\d{6}$/.test(val))) {
        return true
      }
      return false
    },
    navTo(url){
      this.$router.push(url)
    }
  }
}
