import { render, staticRenderFns } from "./ptkf.vue?vue&type=template&id=3c4e2ac0&scoped=true"
import script from "./ptkf.vue?vue&type=script&lang=js"
export * from "./ptkf.vue?vue&type=script&lang=js"
import style0 from "./ptkf.vue?vue&type=style&index=0&id=3c4e2ac0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c4e2ac0",
  null
  
)

export default component.exports