<template>
   <div class="footer-bar">
     <div class="items">
       <div @click="navTo('/shgz')">售后规则</div>
       <div @click="navTo('/cjwt')"> 常见问题</div>
       <div @click="navTo('/aboutUs')"> 关于我们</div>
       <div @click="navTo('/privacy')"> 隐私政策</div>
       <div @click="navTo('/suggestion')">意见反馈</div>
       <div @click="navTo('/ptkf')"> 联系客服</div>
     </div>
     <div class="bei">
       {{ info.icp }}
     </div>
     <div class="copy">
      {{ info.copyright }}
     </div>
   </div>
</template>

<script>
export default {
  name: "FooterBar",
  data(){
    return{
      info:{}
    }
  },
  created(){
    let that=this;
    that.$get('/api/config',{}).then(r=>{
      this.info=r.data;
      document.title =r.data.web_name
    })
  }
}
</script>

<style scoped lang="scss">
   .footer-bar{
     height:181px;
     background-color: #333333;
     color: #fff;
     text-align:center;
     .items{
       padding-top: 49px;
       display: flex;
       align-items: center;
       justify-content: center;
       cursor: pointer;
       div{
         margin-right:50px;
         font-size: 18px
       }
     }
     .bei{
       fong-size: 14px;
       color: #9E9E9E;
       margin-top: 40px
     }
     .copy{
       fong-size: 14px;
       color: #9E9E9E;
       margin-top: 8px
     }

   }
</style>
