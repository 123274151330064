<template>
   <div class="side-nav">
     <el-popover
         ref="popover"
         placement="left"
         trigger="hover"
         >
       <div v-if="info[0]">客服1：{{ info[0].name }} {{ info[0].telephone }}</div>
       <div v-if="info[1]">客服2：{{ info[1].name }} {{ info[1].telephone }}</div>
     </el-popover>
     <div class="nav-items">
        <div class="item" @click="toTop">
          <img src="../assets/images/top.png" alt="">
          <div class="text">回到顶部</div>
        </div>
       <div class="item item-b" v-popover:popover>
         <img src="../assets/images/kefu.png" alt="">
         <div class="text">平台客服</div>
       </div>
       <div class="item" @click="navTo('/suggestion')">
         <img src="../assets/images/yjfk.png" alt="">
         <div class="text">意见反馈</div>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "SideNav",
  data(){
    return{
      info:[]
    }
  },
  mounted(){
    this.getInfo()
  },
  methods:{
    toTop(){
      this.$emit("toPageTop", '1');
    },
    getInfo(){
      this.$get('/api/customer-service').then(res=>{
        this.info = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
   .side-nav{
     position: fixed;
     bottom:318px;
     right:0;
     background-color: #fff;
     .nav-items{
       padding: 14px;
       .item{
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         cursor: pointer;
         img{
           width: 26px;
           height: 26px;

         }
         .text{
           font-size: 12px;
           font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
           font-weight: 400;
           color: #9E9E9E;
         }
       }
       .item-b{
         padding-bottom: 6px;
         padding-top: 2px;
         border-top: 1px solid #e5e5e5;
         border-bottom: 1px solid #e5e5e5;
         margin: 6px 0;
         position: relative;

       }
     }
   }
</style>
