<template>
  <div class="user-msg">
    <div class="title-content">
      <div class="title">我的消息</div>
      <div class="line"></div>
    </div>
    <div class="msg-content" v-if="total>0">
      <div class="msg-items">
        <div class="items flex-items flex-between" v-for="(item,index) in msgList" :key="index" @click="checkMsg(item.id,index)">
          <img src="../../../assets/images/msg.png" alt="">
          <div class="info-item">
            <div class="title">系统消息</div>
            <div class="msg">{{item.content}}</div>
          </div>
          <div>
            <div class="date">{{item.created_at}}</div>
            <div class="notice" v-if="item.read==0">
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="page-view">
        <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            background
            layout="total,sizes,prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty" v-if="hasData&&msgList.length==0">
      <img src="../../../assets/images/empty.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "userMsg",
  data(){
    return{
      msgList:[],
      total:0,
      page:1,
      pageSize:10,
      hasData:false
    }
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      this.$get('/api/message',{limit:this.pageSize,page:this.page}).then(res=>{
        this.total = res.data.total;
        this.msgList=res.data.list
        this.hasData=true
      }).catch(()=>{
        this.hasData=false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    checkMsg(id,index){
      this.$put('/api/message',{id:id}).then(()=>{
        let list=this.msgList;
        list[index].read=1;
        this.msgList=list
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .user-msg{
    .msg-content{
      padding: 31px 25px;
      .msg-items{
          .items{
            padding:21px 18px;
            border: 1px solid #E5E5E5;
            margin-bottom:17px;
            img{
              width: 52px;
              height: 52px;
            }
            .info-item{
              flex:1;
              margin-left: 16px;
              margin-right: 50px;
              .title{
                ont-size: 14px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 400;
                color: #9E9E9E;
              }
              .msg{
                font-size: 18px;
                font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
                margin-top: 10px;
              }
            }
            .date{
              font-size: 14px;
              font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
              font-weight: 400;
              color: #9E9E9E;
            }
            .notice{
              text-align: right;
              margin-top:10px;
              span{
                display: inline-block;
                width: 10px;
                height: 10px;
                background: #F42323;
                border-radius: 50%;
              }
            }
          }
      }
    }
  }
</style>
